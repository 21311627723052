.grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pathbox {
    height: 30px;
    width: 30px;
}

@media only screen and (max-width: 1280px) {
    .pathbox {
        height: 24px;
        width: 24px;
    }
    .grid {
        pointer-events: none;
    }
}
@media only screen and (max-width: 1024px) {
    .pathbox {
        height: 18px;
        width: 18px;
    }
}
@media only screen and (max-width: 800px) {
    .pathbox {
        height: 14px;
        width: 14px;
    }
}
@media only screen and (max-width: 640px) {
    .pathbox {
        height: 10px;
        width: 10px;
    }
}
@media only screen and (max-width: 450px) {
    .pathbox {
        height: 8px;
        width: 8px;
    }
}
