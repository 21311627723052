.normal {
    font-family: Roboto;
    margin: 0;
    min-height:100vh;
    background-color: #eeeeee;
    height: 100%;
    transition:
    color 0.4s,
    background-color 0.4s
}

.darkMode {
    font-family: Roboto;
    color: white;
    background-color: #1A202C;
    margin: 0;
    min-height:100vh;
    height: 100%;
    transition:
    color 0.4s,
    background-color 0.4s
}