@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
html {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.visited {
  background-color: #118ab2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header_header__1VCKf {
    padding: 1rem 0;
    margin-bottom: 10px;
}
.Header_navlist__jhTgy {
    display: flex;
    list-style-type: none;
    margin: 0;
    justify-content: left;
    padding-left: 0;
}
.Header_mleft__3RhVA {
    margin-left: 15px;
    text-align: right;
}

.Header_toptext__2vCsl {
    margin-right: 15px;
}

.Header_selected__3mR6D {
    font-weight: bold;
    border-bottom: 1px solid;
}

@media only screen and (max-width: 600px) {
    .Header_toptext__2vCsl {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .Footer_smallerscreen__2aPOZ  {
        font-size: 0.8rem;
    }
}
.Layout_normal__2RJy6 {
    font-family: Roboto;
    margin: 0;
    min-height:100vh;
    background-color: #eeeeee;
    height: 100%;
    transition:
    color 0.4s,
    background-color 0.4s
}

.Layout_darkMode__VFZTf {
    font-family: Roboto;
    color: white;
    background-color: #1A202C;
    margin: 0;
    min-height:100vh;
    height: 100%;
    transition:
    color 0.4s,
    background-color 0.4s
}
.Bar_bar__1gzdR {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #118ab2;
    margin: 5px;
}

.Bar_comparing__3PC2P {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #ffd166;
    margin: 5px;
}

.Bar_lowest__3z_5O {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #ef476f;
    margin: 5px;
}

.Bar_sorted__3hptE {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #06d6a0;
    margin: 5px;
}

.Bar_pivot__1ynpE {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #073b4c;
    margin: 5px;
}
    
@media only screen and (max-width: 600px) {
    .Bar_bar__1gzdR, .Bar_comparing__3PC2P, .Bar_lowest__3z_5O, .Bar_sorted__3hptE, .Bar_pivot__1ynpE  {
        margin: 1px;
    }
}

.Bar_text__2z8dY {
    color: #000000;
    margin-bottom: 5px;
}

.Bar_nodisplay__1H7ZA {
    display: none;
}
.description_boxyellow__CEuY7 {
    height: 10px;
    width: 10px;
    background-color: #ffd166;
    margin: 10px;
}

.description_boxred__1jj8r {
    height: 10px;
    width: 10px;
    background-color: #ef476f;
    margin: 10px;
}

.description_boxgreen__1qnBm {
    height: 10px;
    width: 10px;
    background-color: #06d6a0;
    margin: 10px;
}

.description_boxblue__-WgI2 {
    height: 10px;
    width: 10px;
    background-color: #073b4c;
    margin: 10px;
}
    
.SortingVisualBox_box__1PlBR {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 300px;
}

.SortingVisualBox_bottom__CAUd6 {
    display: flex;
    justify-content: center;
}

.SortingVisualBox_button__1-SFE {
    margin: 10px !important;
}
.PathBox_grid__19jmH {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PathBox_pathbox__2DDbm {
    height: 30px;
    width: 30px;
}

@media only screen and (max-width: 1280px) {
    .PathBox_pathbox__2DDbm {
        height: 24px;
        width: 24px;
    }
    .PathBox_grid__19jmH {
        pointer-events: none;
    }
}
@media only screen and (max-width: 1024px) {
    .PathBox_pathbox__2DDbm {
        height: 18px;
        width: 18px;
    }
}
@media only screen and (max-width: 800px) {
    .PathBox_pathbox__2DDbm {
        height: 14px;
        width: 14px;
    }
}
@media only screen and (max-width: 640px) {
    .PathBox_pathbox__2DDbm {
        height: 10px;
        width: 10px;
    }
}
@media only screen and (max-width: 450px) {
    .PathBox_pathbox__2DDbm {
        height: 8px;
        width: 8px;
    }
}

.PathFinding_boxyellow__1PN5W {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #ffd166;
}

.PathFinding_boxgreen__13lcg {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #06d6a0;
}

.PathFinding_boxred__2chVw {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #ef476f;
}

.PathFinding_boxblue__3yNWn {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #118ab2;
}

.PathFinding_boxdarkblue__3Cai6 {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #073b4c;
}

.PathFinding_boxorange__3gwTe {
    height: 15px;
    width: 15px;
    margin: 10px;
    background-color: #fb5607;
}

@media only screen and (max-width: 1280px) {
    .PathFinding_box__Mq1iq {
        display: none;
    }
    .PathFinding_textyellow__2Zi57 {
        margin: 10px;
        border-bottom: solid #ffd166;
    }

    .PathFinding_textgreen__18OAu {
        margin: 10px;
        border-bottom: solid #06d6a0;
    }

    .PathFinding_textred__1kSA- {
        margin: 10px;
        border-bottom: solid #ef476f;
    }

    .PathFinding_textblue__oRjPm {
        margin: 10px;
        border-bottom: solid #118ab2;
    }

    .PathFinding_textdarkblue__8vosj {
        margin: 10px;
        border-bottom: solid #073b4c;
    }

    .PathFinding_textorange__1g--- {
        margin: 10px;
        border-bottom: solid #fb5607;
    }
    .PathFinding_pathsettings__Ya4Bd {
        display: none !important;
    }
}

